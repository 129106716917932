<template>
  <div>
    <el-card style="margin-top:20px">
      <!-- 下载模板按钮 -->
      <span>
        <el-button
          type="primary"
          @click="getDownload"
          class="download"
        >
          <a href="http://file.agricultural.maruiwhu.cn/file/upload/goodsStyle/2021-05-06/aa7fb809ac9345d6975642c6b06e5a68.xlsx">下载模板</a>
        </el-button>
      </span>
      <span style="margin-left:20px;">
        <el-upload
          style="margin-left:130px;margin-top:-39px"
          ref="upload"
          :action="uploadUrl"
          :limit="1"
          :before-upload="beforeUpload"
          :data="importData"
          accept=".xlsx"
          class="up-class"
          :on-success="uploadSuccess"
          :show-file-list="false"
          :auto-upload="true"
          :headers="headers"
        >
          <el-button type="primary">导入模板</el-button>
        </el-upload>
      </span>
      <!-- 新增摊位按钮 -->
      <span style="margin-left:30px">
        <el-button
          style="position: relative;margin-left:230px;top:-39px"
          @click="addBooth"
        >新增摊位</el-button>
      </span>
      <div class="market-top">
        <!-- 摊位编号查询 -->
        <div class="market-name">
          <span>摊位编号:</span>
          <el-input
            placeholder="请输入摊位编号"
            v-model="queryInfo.stallName"
            clearable
            @change="searchBooth"
            @clear="getBoothList"
            class="input-with-select"
          >
            <el-button
              slot="append"
              @click="searchBooth"
            >搜索</el-button>
          </el-input>
        </div>
        <!-- 摊位状态 -->
        <div class="market-status">
          <span>摊位状态：</span>
          <el-select
            v-model="queryInfo.status"
            placeholder="全部"
            clearable
            @clear="getBoothList"
            @change="searchBooth"
          >
            <el-option
              v-for="item in boothStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 所属楼层 -->
        <div class="market-floor">
          <span>所属楼层：</span>
          <el-select
            v-model="queryInfo.floor"
            placeholder="全部"
            clearable
            @clear="getBoothList"
            @change="searchBooth"
          >
            <el-option
              v-for="item in boothFloor"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 所属分区 -->
        <div class="market-class">
          <span>所属分区：</span>
          <el-select
            v-model="queryInfo.groupName"
            placeholder="全部"
            clearable
            @clear="getBoothList"
            @change="searchBooth"
          >
            <el-option
              v-for="item in boothSubarea"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="booth-main">
        <el-card
          v-for="(item, index) in boothList"
          :key="index"
        >
          <div v-if="item.merchantsInfoVo">
            <div class="marketNo">{{ item.stallName }}</div>
            <img
              class="imgs"
              src="../../assets/img/boothAdmin/shopName.png"
            />
            <div class="booth-shopName">
              {{ item.merchantsInfoVo.shopName }}
            </div>
            <div class="booth-operator">
              <span>经营人：</span>{{ item.userName }}
            </div>
            <div class="booth-validTime">
              <span>有效期：</span>{{ item.merchantsInfoVo.businessBegin }}至{{
                item.merchantsInfoVo.businessEnd
              }}
            </div>
          </div>
          <div v-else>
            <div class="marketNo">{{ item.stallName }}</div>
            <span>
              <img
                class="imgs"
                src="../../assets/img/boothAdmin/leisure.png"
              /></span>

            <span class="marketStatus">空闲中</span>

            <div class="marketBtn">
              <!-- <template slot-scope="scope"> -->
              <el-button
                class="entering"
                type="primary"
                size="small"
                @click="getBoothShop(item)"
              >录入商户</el-button>
              <el-button
                class="delect"
                type="danger"
                size="small"
                @click="delectBoothShop(item.id)"
              >
                删除</el-button>
              <!-- </template> -->
            </div>
          </div>
        </el-card>
      </div>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        @size-change="handleSizeChange"
        :current-page="queryInfo.pageNo"
        :page-size="queryInfo.limit"
        :page-sizes="[15, 30, 60, 120]"
        @current-change="handleCurrentChange"
        layout="total,sizes,prev,pager,next,jumper"
      ></el-pagination>
    </el-card>
    <!-- 新增摊位 -->
    <el-dialog
      title="新增摊位"
      :visible.sync="addBoothGialogVisible"
      width="500px"
      @close="addBoothDialogClosed"
    >
      <el-form
        ref="addFormRef"
        :model="addBoothForm"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item
          label="楼层："
          class="addFloor"
          prop="floor"
        >
          <el-select
            v-model="addBoothForm.floor"
            placeholder="请选择楼层"
            clearable
            @clear="getBoothList"
            @change="getBoothList"
          >
            <el-option
              v-for="item in boothFloor"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="摊位分区："
          class="addGroupName"
          prop="groupName"
        >
          <el-select
            v-model="addBoothForm.groupName"
            placeholder="请选择摊位分区"
            clearable
            @clear="getBoothList"
            @change="getBoothList"
          >
            <el-option
              v-for="item in boothSubarea"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="摊位编号："
          prop="stallName"
        >
          <el-input
            v-model="addBoothForm.stallName"
            placeholder="前缀+编号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="addbtn">
          <el-button @click="addBoothGialogVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click="addUnitBooth"
          >确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 录入商户信息 -->
    <el-dialog
      title="录入商户信息"
      :visible.sync="boothGialogVisible"
      width="800px"
      @close="clearInfo"
    >
      <el-form
        ref="addBothFormRef"
        :rules="addBothFormRules"
        :model="merchantsForm"
        label-width="100px"
      >
        <div class="first">
          <el-form-item
            label="经营人："
            prop="userName"
          >
            <el-input
              v-model="merchantsForm.userName"
              placeholder="请输入经营人姓名"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="性别："
            prop="sex"
          >
            <el-select
              v-model="merchantsForm.sex"
              placeholder="请选择性别"
              clearable
              class="select"
            >
              <el-option
                v-for="(item, index) in sexList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="second">
          <el-form-item
            label="身份证号码："
            prop="idCardNum"
          >
            <el-input
              v-model="merchantsForm.merchantsInfo.idCardNum"
              placeholder="请输入身份证号码"
              @input="change"
              clearable
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系方式："
            prop="mobile"
          >
            <el-input
              v-model="merchantsForm.mobile"
              placeholder="请输入联系方式"
              clearable
            ></el-input>
          </el-form-item>
        </div>
        <div class="third">
          <el-form-item
            label="店铺名称："
            prop="shopName"
          >
            <el-input
              v-model="merchantsForm.merchantsInfo.shopName"
              placeholder="请输入店铺名称"
              clearable
              @input="change"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="经营范围："
            prop="merchantCategoryId"
          >
            <el-select
              v-model="newArr"
              placeholder="请选择经营范围"
              clearable
              multiple
              class="select"
              @change="select"
            >
              <el-option
                v-for="(item, index) in cateList"
                :key="index"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item
          label="摊位号："
          prop="stallId"
        >
          <el-input
            v-model="merchantsForm.merchantsInfo.stallName"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="店铺有效期："
          prop="time"
        >
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            @change="chooseTime"
          >
          </el-date-picker>
        </el-form-item>
        <div class="five">
          <el-form-item label="店铺头像：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType1"
              :before-upload="beforeUpload1"
              :on-success="handlesuccess1"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.avatar"
                :src="merchantsForm.avatar"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>

          <el-form-item label="营业执照：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType2"
              :before-upload="beforeUpload1"
              :on-success="handlesuccess2"
              :show-file-list="false"
            >
              <img
                v-if="businessLicence"
                :src="businessLicence"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="健康证：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType3"
              :before-upload="beforeUpload1"
              :on-success="handlesuccess3"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.merchantsInfo.healthCertificate"
                :src="merchantsForm.merchantsInfo.healthCertificate"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="安全许可证：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType4"
              :before-upload="beforeUpload1"
              :on-success="handlesuccess4"
              :show-file-list="false"
            >
              <img
                v-if="merchantsForm.merchantsInfo.hygienicLicense"
                :src="merchantsForm.merchantsInfo.hygienicLicense"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </div>
        <div class="six">
          <el-form-item label="微信收款二维码：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType5"
              :on-success="handlesuccess5"
              :show-file-list="false"
              :http-request="resolveQR1"
            >
              <img
                v-if="merchantsForm.merchantsInfo.wxQrCode"
                :src="merchantsForm.merchantsInfo.wxQrCode"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="支付宝收款二维码：">
            <el-upload
              action="/api/v1/file/file"
              :data="this.bizType6"
              :on-success="handlesuccess6"
              :show-file-list="false"
              :http-request="resolveQR2"
            >
              <img
                v-if="merchantsForm.merchantsInfo.zfbQrCode"
                :src="merchantsForm.merchantsInfo.zfbQrCode"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-picture-outline avatar-uploader-icon"
              ></i>
            </el-upload>
          </el-form-item>
        </div>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
        <el-button @click="boothGialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getQrUrl } from '../../api/common.js'
import QrCode from 'qrcode-decoder'
// import XLSX from 'xlsx'
export default {
  data () {
    // 手机号校验规则
    var checkMobile = (rule, value, callback) => {
      // const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      const regMobile = /^(0|86|17951)?(1[0-9])[0-9][0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }

    return {
      // value: '',
      queryInfo: {
        limit: 15,
        pageNo: 1,
        // 摊位id
        stallName: '',
        // 市场id
        marketId: '',
        // 楼层
        floor: '',
        // 分区
        groupName: '',
        // 状态
        status: ''
      },
      total: 0,
      // 市场列表
      boothList: [],
      // 新增摊位对话框
      addBoothGialogVisible: false,
      // 新增摊位的form表单
      addBoothForm: {
        floor: '',
        groupName: '',
        marketId: '',
        stallName: ''
      },
      boothStatusValue: '',
      // 录入商户的对话框
      boothGialogVisible: false,
      // 摊位状态
      boothStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '空闲中'
        },
        {
          value: '1',
          label: '使用中'
        }
      ],
      // 楼层
      boothFloor: [],
      // 摊位分区
      boothSubarea: [],
      query: {
        marketId: ''
      },
      // 增摊位校验规则
      addFormRules: {
        floor: [{ required: true, message: '请选择楼层', trigger: 'blur' }],
        stallName: [
          { required: true, message: '请输入摊位号', trigger: 'blur' }
        ]
      },
      // 录入商户校验规则
      addBothFormRules: {
        mobile: [
          { required: false, message: '请填写手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        idCardNum: [
          { required: false, message: '请填写身份号码', trigger: 'blur' }
        ]
      },
      // 店铺有效期
      value1: '',
      // 图片路径
      imageUrl: '',
      importData: {
        // 参数
        marketId: ''
      },
      uploadUrl: '', // 地址
      headers: {
        'admin-authorization': ''
      },
      // 添加商家用户的入参
      merchantsForm: {
        avatar: '',
        marketId: undefined, // 市场id
        mobile: '',
        userName: '', // 商户名称
        sex: '', // 性别 0-女 1-男 2-未设置
        merchantsInfo: {
          businessBegin: '',
          businessEnd: '',
          businessLicence: [], // 营业执照
          healthCertificate: '', // 健康证
          hygienicLicense: '', // 卫生许可证
          idCardNum: '', // 身份证号码
          merchantCategoryId: [], // 业态id
          shopName: '',
          stallId: [], // 摊位id
          stallName: '', // 摊位名称
          wxQrCode: '', // 微信收款码
          zfbQrCode: '', // 支付宝收款码
          aliPayQrCode: '',
          wxPayQrCode: ''
        }
      },
      // 性别
      sexList: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 0,
          name: '女'
        }
      ],
      // 商品类别
      cateList: [],
      // 摊位数组
      stallList: [],
      // 上传个人头像的type
      bizType1: {
        bizType: 'shopUser'
      },
      bizType2: {
        bizType: 'businessLicence'
      },
      bizType3: {
        bizType: 'healthCertificate'
      },
      bizType4: {
        bizType: 'hygienicLicense'
      },
      bizType5: {
        bizType: 'wxQrCode'
      },
      bizType6: {
        bizType: 'zfbQrCode'
      },
      time: '',
      // 营业执照
      businessLicence: '',
      // value: '',
      // 经营范围新数组
      newArr: [],
      stallId: []
    }
  },
  created () {
    this.getBoothList()
    this.getFloorList()
    this.getGoodCateList()
    this.getBoothFloor()
    this.headers['admin-authorization'] = window.sessionStorage.getItem('token')
    // console.log(this.headers['admin-authorization'])
  },
  mounted () {
    this.marketId = window.sessionStorage.getItem('currentMarketId')
    this.importData.marketId = this.marketId
    this.uploadUrl = '/api/v1/admin/agriculturalMarketStall/import/'
  },
  methods: {
    searchBooth () {
      this.queryInfo.pageNo = 1
      this.getBoothList()
    },
    // 摊位列表
    async getBoothList () {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const { data: res } = await this.$http.get(
        'agriculturalMarketStall/list',
        { params: this.queryInfo }
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.boothList = res.data.data
      this.total = res.data.total
      // console.log(res, 11)
    },
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getBoothList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getBoothList()
    },
    // 新增摊位的对话框打开
    addBooth () {
      this.addBoothGialogVisible = true
    },
    // 单个新增摊位提交对话框
    addUnitBooth () {
      this.addBoothForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        const res = await this.$http.post(
          'agriculturalMarketStall/',
          this.addBoothForm
        )
        // console.log(res, 11)
        // this.getBoothList()
        this.addBoothGialogVisible = false
        if (res.data.code !== 0) return this.$message.error(res.data.message)
        this.$message.success('新增市场摊位成功')
        this.getBoothList()
      })
    },
    // 监听新增摊位对话框的显示与隐藏
    addBoothDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    async getBoothFloor () {
      this.addBoothForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const res = await this.$http.get('agriculturalMarketStall/floor', {
        params: this.queryInfo
      })
      if (res.data.code !== 0) {
        return this.$message.error(res.message)
      }
      this.boothFloor = res.data.data
      // console.log(this.boothFloor, '楼层')
    },
    // 所属分区
    async getFloorList () {
      this.addBoothForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const res = await this.$http.get('agriculturalMarketStall/groupName', {
        params: this.queryInfo
      })
      if (res.data.code !== 0) {
        return this.$message.error(res.message)
      }
      this.boothSubarea = res.data.data
      // console.log(this.boothSubarea, '分区')
    },
    // 下载摊位
    async getDownload () { },
    // 导入摊位
    beforeUpload (file) {
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (extension !== 'xlsx') {
        this.$message({
          message: '上传文件只能是 xlsx格式!',
          type: 'warning'
        })
        return false
      }
      // this.getBoothList()
    },
    uploadSuccess (res, file, fileList) {
      // console.log(res.code, 111)
      // console.log(res, 11122)
      if (res.code !== 0) {
        this.$message({
          message: '导入失败!',
          type: 'error'
        })
      } else {
        this.$message({
          message: '导入成功!',
          type: 'success'
        })
      }
      this.$refs.upload.clearFiles()
      this.getBoothList()
    },
    // 删除摊位
    async delectBoothShop (id) {
      // console.log(id, 22211)
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除摊位，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `agriculturalMarketStall/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除摊位失败')
      }
      this.$message.success('删除摊位成功')
      this.getBoothList()
    },
    // 强制刷新input
    change (e) {
      this.$forceUpdate()
    },
    select (e) {
      this.$forceUpdate()
    },
    // 经营时间范围
    chooseTime () {
      // console.log(this.time)
      // 赋值给入参
      this.merchantsForm.merchantsInfo.businessBegin = this.time[0]
      this.merchantsForm.merchantsInfo.businessEnd = this.time[1]
    },
    beforeUpload1 (file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    // 监听头像上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.avatar = response.data
    },
    // 监听上传成功的钩子
    handlesuccess2 (response, file, fileList) {
      this.$forceUpdate()
      this.businessLicence = response.data
      this.merchantsForm.merchantsInfo.businessLicence = [response.data]
    },
    handlesuccess3 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.healthCertificate = response.data
      console.log(this.merchantsForm.merchantsInfo.healthCertificate)
    },
    handlesuccess4 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.hygienicLicense = response.data
    },
    handlesuccess5 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.wxQrCode = response.data
    },
    handlesuccess6 (response, file, fileList) {
      this.$forceUpdate()
      this.merchantsForm.merchantsInfo.zfbQrCode = response.data
    },
    // 识别微信
    resolveQR (event) {
      console.log(event)
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
            this.$message.success('识别二维码成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
    },
    resolveQR1 (event) {
      console.log(event)
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
            this.$message.success('微信二维码识别成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
      var form = new FormData()
      form.append('file', event.file)
      form.append('bizType', 'wxQrCode')
      this.$http.post('/file/file', form).then(res2 => {
        console.log(res2)
        this.$forceUpdate()
        this.merchantsForm.merchantsInfo.wxQrCode = res2.data.data
        // 上传成功 调用onSuccess方法，
        // 处理自己的逻辑
        // this.handlesuccess5(res2) // 必须传参res,否则在onsuccess的参数里面，response属性值为undefined，如果传了值，则会把res的值作为response的属性值
      })
    },
    // 识别支付宝
    resolveQR2 (event) {
      const result = getQrUrl(event.file)
      result
        .then(res => {
          if (res.data) {
            console.log(res.data)
            this.merchantsForm.merchantsInfo.aliPayQrCode = res.data
            this.$message.success('支付宝二维码识别成功!')
          } else {
            this.$message.error('识别二维码失败, 请重新上传')
          }
        })
        .catch(err => {
          this.$message.error(JSON.stringify(err))
        })
      var form = new FormData()
      form.append('file', event.file)
      form.append('bizType', 'zfbQrCode')
      this.$http.post('/file/file', form).then(res2 => {
        console.log(res2)
        this.$forceUpdate()
        this.merchantsForm.merchantsInfo.zfbQrCode = res2.data.data
        // 上传成功 调用onSuccess方法，
        // 处理自己的逻辑
        // this.handlesuccess5(res2) // 必须传参res,否则在onsuccess的参数里面，response属性值为undefined，如果传了值，则会把res的值作为response的属性值
      })
    },
    // 调用业态 经营范围
    async getGoodCateList () {
      const { data: res } = await this.$http.get(
        'merchantCategory/list?limit=200&pageNo=1'
      )
      if (res.code !== 0) {
        return this.$message.error('获取业态失败')
      }
      this.cateList = res.data.data
    },
    // 打开录入商户对话框
    getBoothShop (row) {
      this.boothGialogVisible = true
      console.log(row, '打开录入')
      // 摊位号
      this.merchantsForm.merchantsInfo.stallName = row.stallName
      this.stallId = [row.id]
      console.log(this.stallId, '摊位ID')
      // 赋值marketId
      this.merchantsForm.marketId = row.marketId
      // console.log(this.merchantsForm.marketId, 11)
      // 先把经营范围给赋值
      this.merchantsForm.merchantsInfo.merchantCategoryId = this.newArr
    },
    async handleClick () {
      var qr = new QrCode()
      const res = await qr.decodeFromImage(
        this.merchantsForm.merchantsInfo.wxQrCode,
        {
          crossOrigin: '*'
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (res) {
        this.merchantsForm.merchantsInfo.wxPayQrCode = res.data
        console.log(this.merchantsForm.merchantsInfo.wxPayQrCode)
      }
    },
    // 监听录入商户对话框的显示与隐藏
    clearInfo () {
      // this.merchantsForm = []
      this.merchantsForm.avatar = ''
      // this.merchantsForm = {}
      // this.merchantsForm.merchantsInfo = {}
      this.time = []
      this.merchantsForm.merchantsInfo.shopName = ''
      this.merchantsForm.merchantsInfo.idCardNum = ''
      this.merchantsForm.merchantsInfo.businessLicence = []
      this.merchantsForm.merchantsInfo.healthCertificate = ''
      this.merchantsForm.merchantsInfo.hygienicLicense = ''
      this.merchantsForm.merchantsInfo.wxQrCode = ''
      this.merchantsForm.merchantsInfo.zfbQrCode = ''
      this.businessLicence = ''
      // 保证在当前市场下
      this.merchantsForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      // 把经营范围数组清空
      this.newArr = []
      this.newArr = []
      // this.merchantsForm.merchantsInfo = ''
      // this.merchantsForm.merchantsInfo.stallName = row.stallName
      this.$refs.addBothFormRef.resetFields()
      // this.boothGialogVisible = false
    },
    // 提交添加信息
    async submit () {
      // 先把经营范围给赋值
      this.merchantsForm.merchantsInfo.stallId = this.stallId
      console.log(this.merchantsForm.merchantsInfo.stallId, 222)
      this.merchantsForm.merchantsInfo.merchantCategoryId = this.newArr
      // console.log(this.merchantsForm.merchantsInfo.merchantCategoryId, 222)
      // 可以发起网络请求
      // this.$refs.merchantsForm.validate(async (valid) => {
      // if (valid) return
      const { data: res } = await this.$http.post(
        'merchants',
        this.merchantsForm
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.$message.success('录入商户成功')
      this.getBoothList()
      this.boothGialogVisible = false
      // })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/boothAdmin.less';
.el-row {
  margin-bottom: 20px;
  width: 1366px;
}
.el-col {
  margin-right: 20px;
}
// .el-col:last-child {
//   margin-left: 110px;
// }
// img {
//   width: 70px;
//   height: 70px;
// }
.businessImg {
  img {
    display: block;
    margin-top: 10px;
    // width: 200px;
    // height: 200px;
    object-fit: cover;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.first,
.second,
.third {
  display: flex;
  justify-content: space-between;
}

.six,
.five {
  display: flex;
  justify-content: flex-start;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
}
.input:nth-child(3) {
  margin-left: -30px;
}
.input:last-child {
  margin-left: -60px;
}
.el-select {
  // width: 215px;
  width: 150px;
}
.select {
  width: 215px;
}
.el-input {
  width: 215px;
}
.secondrow {
  display: flex;
  width: 1227px;
  margin-left: -15px;
}
</style>
